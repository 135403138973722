import { API } from 'aws-amplify';
import { getHeaders } from '../../../services/utils';

interface RegisterCourseInScormCloudPayload {
  enterpriseId: string,
  courseId: string,
  registrationId: string,
  userId: string,
}

interface GetRegistrationLaunchLinkPayload {
  regId:string,
  enterpriseId: string
}

const registerCourseInScormCloud = async (
  params:RegisterCourseInScormCloudPayload,
) : Promise<void> => {
  const {
    enterpriseId, courseId, registrationId, userId,
  } = params;
  try {
    const api = 'enterpriseappscormwrapper';
    const path = '/registrations';
    const headers = await getHeaders();
    const payload = {
      enterpriseId,
      courseId,
      registrationId,
      userId,
    };
    await API.post(api, path, {
      headers,
      body: payload,
    });
  } catch (e) {
    console.log(e);
  }
};

const getRegistrationLaunchLink = async (
  params: GetRegistrationLaunchLinkPayload,
) :Promise<string> => {
  const { regId, enterpriseId } = params;
  try {
    const api = 'enterpriseappscormwrapper';
    const path = `/registrations/${regId}/launchLink`;
    const headers = await getHeaders();
    const { origin } = window.location;
    const payload = {
      redirectOnExitUrl: `${origin}/learner/redirect/`,
      enterpriseId,
    };
    const response = await API.post(api, path, {
      headers,
      body: payload,
    });
    return response.data.launchLink;
  } catch (e) {
    return '';
  }
};

export { registerCourseInScormCloud, getRegistrationLaunchLink };

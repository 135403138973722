// extracted by mini-css-extract-plugin
export var courseDetailsPageLoader = "CourseDetails-module--courseDetailsPageLoader--39xIv";
export var courseDetails = "CourseDetails-module--courseDetails--14i6b";
export var headingSection = "CourseDetails-module--headingSection--121La";
export var courseSection = "CourseDetails-module--courseSection--ArBGk";
export var infoSection = "CourseDetails-module--infoSection--2y5Yu";
export var part = "CourseDetails-module--part--1Szee";
export var info = "CourseDetails-module--info--2rjKU";
export var title = "CourseDetails-module--title--1NTus";
export var update = "CourseDetails-module--update--3eb7z";
export var hiddenCourse = "CourseDetails-module--hiddenCourse--2EDGa";
export var ctaWrapper = "CourseDetails-module--ctaWrapper--3sEPl";
export var cta = "CourseDetails-module--cta--wKMTE";
export var assignCourseCta = "CourseDetails-module--assignCourseCta--uOwn7";
export var shareIcon = "CourseDetails-module--shareIcon--3hcnh";
export var hideCta = "CourseDetails-module--hideCta--3SOUb";
export var sectionHeading = "CourseDetails-module--sectionHeading--Xf52L";
export var courseInfoSection = "CourseDetails-module--courseInfoSection--3Whaa";
export var accreditationCourseDetails = "CourseDetails-module--accreditationCourseDetails--3rHww";
export var week = "CourseDetails-module--week--2zi2X";
export var skillLevel = "CourseDetails-module--skillLevel--18zZD";
export var sharable = "CourseDetails-module--sharable--1HGMo";
export var certifiedBy = "CourseDetails-module--certifiedBy--1Siyp";
export var language = "CourseDetails-module--language--CBM_4";
export var bodySection = "CourseDetails-module--bodySection--9apVO";
export var wrapper = "CourseDetails-module--wrapper--1YTpf";
export var courseOverviewWrapper = "CourseDetails-module--courseOverviewWrapper--3X5S8";
export var courseJourneyWrapper = "CourseDetails-module--courseJourneyWrapper--3J_mo";
export var journey = "CourseDetails-module--journey--3WFpB";
export var moduleWrapper = "CourseDetails-module--moduleWrapper--3VHGd";
export var startJourney = "CourseDetails-module--startJourney--3Sl1-";
export var endJourney = "CourseDetails-module--endJourney--2wPpU";
export var divider = "CourseDetails-module--divider--2-fyd";
export var moduleDiv = "CourseDetails-module--moduleDiv--3fptZ";
export var moduleName = "CourseDetails-module--moduleName--1YhP4";
export var weeks = "CourseDetails-module--weeks--r83mx";
export var courseInfo = "CourseDetails-module--courseInfo--3YzHi";
export var courseDescription = "CourseDetails-module--courseDescription--l9jh7";
export var accreditationSection = "CourseDetails-module--accreditationSection--3mwCB";
export var accreditationSectionTitle = "CourseDetails-module--accreditationSectionTitle--KaA8t";
export var imageWrapper = "CourseDetails-module--imageWrapper--3gtXA";
export var moduleInfoSection = "CourseDetails-module--moduleInfoSection--xhGK4";
export var moduleNo = "CourseDetails-module--moduleNo--3RH8m";
export var beginner = "CourseDetails-module--beginner--GCdnk";
export var intermediate = "CourseDetails-module--intermediate--1B-W6";
export var expert = "CourseDetails-module--expert--3hXCa";
export var advanced = "CourseDetails-module--advanced--2TuIA";
export var moduleDescription = "CourseDetails-module--moduleDescription--4QShb";
export var lesson = "CourseDetails-module--lesson--1Hoij";
export var lessonExpand = "CourseDetails-module--lessonExpand--1337o";
export var lessonCollapse = "CourseDetails-module--lessonCollapse--CAzFY";
export var expand = "CourseDetails-module--expand--1b-qY";
export var collapse = "CourseDetails-module--collapse--1px6L";
export var relatedCoursesWrapper = "CourseDetails-module--relatedCoursesWrapper--j0faY";
export var relatedCourses = "CourseDetails-module--relatedCourses--125Pu";
export var courses = "CourseDetails-module--courses--2r8_p";
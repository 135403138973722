import React, { useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import { fetchUserBySub } from '../DataStore/Services';
import CourseDetails from '../Admin/Browse/CourseDetails';
import { createCourseRegistration } from '../../graphql/mutations';
import {
  Course, CourseRegistration, CourseRegistrationStatus,
} from '../../models';
import { navigateTo, PageKind } from './Services/LearnerNavigations';
import { getCurrentUser, getUserRole } from '../Shared/Services/AuthService';
import LearnerContext, { ContextState } from '../../context/Learner/LearnerContext';
import { isBDMUser, postEvent, toaster } from '../../services/utils';
import { CourseAccessType } from '../../API';
import { registerCourseInScormCloud } from './Services/ScormCloudWrapperService';
import { getCourseById } from '../GraphQL/Services';

type Props = {
  courseId?: string,
  path?: string,
  default?: boolean,
}

enum CourseDetailViews {
  LOADING = 'loading',
  COURSE_DATA_PRESENT = 'course_data_present',
  COURSE_DATA_ABSENT = 'course_data_absent'
}

/**
 *
 *
 * @return {*}
 */
const CourseDetailsView: React.FC<Props> = (props) => {
  const [courseData, setCourseData] = useState<Course>({} as Course);
  const [registration, setRegistration] = useState<(CourseRegistration &
  {registrationId: string}) | null>(null);
  const [
    courseDetailViewState,
    setCourseDetailsViewState,
  ] = useState<CourseDetailViews>(CourseDetailViews.LOADING);
  const [disableStartCourseCta, setDisableStartCourseCta] = useState(false);

  const {
    courseRegistrations,
    setCourseRegistrations,
    profileInfo,
    courseRegistrationsWithStatus,
    setCourseRegistrationsWithStatus,
    setContextState,
    contextState,
  } = useContext(LearnerContext);

  /**
   *
   *
   */
  const fetchCourseData = async () => {
    const { courseId } = props;
    const res = await getCourseById(courseId);
    setCourseData(res);
  };

  /**
   *
   * fetches registration to current course.
   */
  const fetchRegistration = async () => {
    const { courseId } = props;
    const courseRegWithStatus = courseRegistrationsWithStatus.filter(
      (reg: any) => reg.id === courseId,
    )[0];
    setRegistration(courseRegWithStatus);
  };

  /**
   *
   * request a single course.->changed to start the course directly
   */
  const requestCourse = async () => {
    const user = await getCurrentUser();
    const subId = user?.attributes?.sub;
    const id = user?.attributes?.['custom:active_user'];
    const userRes = await fetchUserBySub(subId, id);
    const role = getUserRole(userRes.roles);
    const isLearner = role === 'LEARNER';
    const status: CourseRegistrationStatus = CourseRegistrationStatus.APPROVED;
    const res: any = await API.graphql({
      query: createCourseRegistration,
      variables: {
        input: {
          courseID: courseData?.id,
          userID: userRes.id,
          status,
          userSub: subId,
          enterpriseID: profileInfo?.enterpriseID,
        },
      },
    });

    const reg = res?.data?.createCourseRegistration;
    if (!isLearner && reg) {
      setCourseRegistrations([...courseRegistrations, {
        ...reg,
        course: courseData,
      }]);
    }

    if (courseData?.accessType === CourseAccessType.PRIVATE) {
      await registerCourseInScormCloud({
        enterpriseId: profileInfo?.enterpriseID || '',
        courseId: courseData.id,
        registrationId: reg?.id,
        userId: profileInfo.id,
      });
    }

    const regWithStatus = courseRegistrationsWithStatus.map((registrationWithStatus: any) => {
      if (registrationWithStatus.id === courseData.id) {
        return {
          ...registrationWithStatus,
          status,
        };
      } return registrationWithStatus;
    });
    setCourseRegistrationsWithStatus(regWithStatus);
    setContextState(ContextState.READY);
    toaster('Course Added');
    setRegistration({
      ...reg,
      status,
    });
    setTimeout(() => {
      navigateTo(PageKind.COURSE_REGISTRATION, {
        regId: reg.id,
      });
    }, 1000);

    const registrationId = res?.data?.createCourseRegistration?.id;
    const payload = {
      event: 'Course Started',
      userId: profileInfo?.id,
      enterpriseId: profileInfo?.enterpriseID,
      originalTimestamp: dayjs().toISOString(),
      sentAt: dayjs().toISOString(),
      properties: {
        courseId: courseData?.id,
        courseSource: courseData?.source,
        courseSourceId: courseData?.sourceID,
        courseRegistrationId: registrationId,
        courseTitle: courseData?.courseName,
        exclude: isBDMUser(profileInfo.email),
        courseAccessType: courseData?.accessType,
      },
    } as any;
    postEvent(payload);
  };

  /**
   *
   * navigates to the registration.
   */
  const redirectToRegistration = () => {
    navigateTo(PageKind.COURSE_REGISTRATION, {
      regId: registration?.registrationId,
    });
  };

  const CourseStatusMapping = {
    REQUESTED: {
      cta: 'Requested',
      action: () => {},
      id: 'learner-course-details-requested',
    },
    APPROVED: {
      cta: 'Continue Learning',
      action: redirectToRegistration,
      id: 'learner-course-details-continue-learning',
    },
    COMPLETED: {
      cta: 'Continue Learning',
      action: redirectToRegistration,
      id: 'learner-course-details-continue-learning',
    },
    ASSIGNED: {
      cta: 'Start Now',
      action: requestCourse,
      id: 'learner-course-details-start-now',
    },
    DEFAULT: {
      cta: 'Start Now',
      action: requestCourse,
      id: 'learner-course-details-start-now',
    },
  };

  useEffect(() => {
    if (!props.courseId) {
      navigateTo(PageKind.BROWSE);
    }
    fetchCourseData();
  }, []);

  useEffect(() => {
  // Context state being equal to 1 meaning the context data is fetched and isn't default data.
    if (contextState === 1) {
      if (courseData) {
        setCourseDetailsViewState(CourseDetailViews.COURSE_DATA_PRESENT);
      } else {
        setCourseDetailsViewState(CourseDetailViews.COURSE_DATA_ABSENT);
      }
    }
  }, [contextState]);

  useEffect(() => {
    if (courseRegistrationsWithStatus.length !== 0) {
      fetchRegistration();
    }
  }, [courseRegistrationsWithStatus]);

  return (
    <>
      {
        courseDetailViewState === CourseDetailViews.LOADING
        && <Spin tip="Loading..." style={{ height: '100vh' }} />
      }
      {courseDetailViewState === CourseDetailViews.COURSE_DATA_PRESENT
        && (
          <CourseDetails
            course={courseData}
            disableStartCourseCta={disableStartCourseCta}
            courseDetailsCtaHandler={() => {
              setDisableStartCourseCta(true);
              if (registration?.status) {
                CourseStatusMapping[registration.status].action();
              } else {
                CourseStatusMapping.DEFAULT.action();
              }
            }}
            courseDetailsCtaText={registration?.status
              ? CourseStatusMapping[registration?.status].cta
              : CourseStatusMapping.DEFAULT.cta}
            domID={registration?.status
              ? CourseStatusMapping[registration?.status].id
              : CourseStatusMapping.DEFAULT.id}
            browseRoute="/learner/browse/"
          />
        )}
      {courseDetailViewState === CourseDetailViews.COURSE_DATA_ABSENT
        && <></>}
    </>
  );
};

export default CourseDetailsView;

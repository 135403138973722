import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import CourseDetailsView from '../../../components/Learner/CourseDetailsView';
import Authenticate from '../../../components/Identity/Authenticate';
import { viewedPage } from '../../../services/TrackActionService';
import { PageKind } from '../../../components/Learner/Services/LearnerNavigations';
import Layout from '../../../components/Learner/Layout';
import { LearnerProvider } from '../../../context/Learner/LearnerContext';

/**
 *
 *
 * @return {*}
 */
const CourseDetailsPage: React.FC = () => {
  useEffect(() => {
    viewedPage({
      pageKind: PageKind.COURSE_DETAILS,
    });
  }, []);
  return (
    <LearnerProvider>
      <Layout>
        <Router basepath="/learner/course-details">
          <CourseDetailsView
            path="/:courseId"
          />
          <CourseDetailsView
            default
          />
        </Router>
      </Layout>
    </LearnerProvider>
  );
};

export default Authenticate(CourseDetailsPage);

import { Analytics } from 'aws-amplify';

/**
 *
 *
 * @param {*} eventName
 * @param {*} eventAttributes
 */
const recordEvent = (name, attributes) => {
  Analytics.record({
    name,
    attributes,
  });
};

/**
 *
 *
 * @param {*} {
 *   pageKind,
 * }
 */
const viewedPage = ({
  pageKind,
}) => {
  recordEvent('ViewedPage', {
    pageKind,
  });
};

/**
 *
 *
 * @param {*} params
 */
const viewedContent = ({
  courseRegistrationId,
  taskNumber,
  courseName,
  courseId,
  moduleNumber,
}) => {
  recordEvent('ViewedContent', {
    courseRegistrationId,
    moduleNumber,
    taskNumber,
    courseName,
    courseId,
  });
};

/**
 *
 *
 * @param {*} {
 *   courseRegistrationId,
 *   taskIndex,
 *   courseName,
 *   courseId,
 * }
 */
const taskStarted = ({
  courseRegistrationId,
  taskNumber,
  courseName,
  courseId,
  moduleNumber,
}) => {
  recordEvent('Task Started', {
    courseRegistrationId,
    moduleNumber,
    taskNumber,
    courseName,
    courseId,
  });
};

/**
 *
 *
 * @param {*} {
 *   courseRegistrationId,
 *   taskIndex,
 *   courseName,
 *   courseId,
 * }
 */
const taskCompleted = ({
  courseRegistrationId,
  taskNumber,
  courseName,
  courseId,
  moduleNumber,
}) => {
  recordEvent('Task Completed', {
    courseRegistrationId,
    moduleNumber,
    taskNumber,
    courseName,
    courseId,
  });
};

/**
 *
 *
 * @param {*} {
 *   courseId,
 *   courseName,
 * }
 */
const courseRequested = ({
  courseId,
  courseName,
}) => {
  recordEvent('CourseRequested', {
    courseId,
    courseName,
  });
};

/**
 *
 *
 * @param {*} {
 *   courseId,
 *   courseName,
 * }
 */
const courseApproved = ({
  courseId,
  courseName,
}) => {
  recordEvent('CourseApproved', {
    courseId,
    courseName,
  });
};

/**
 *
 *
 * @param {*} {
 *   action,
 * }
 */
const CTAClicked = ({
  action,
}) => {
  recordEvent('CTAClicked', {
    action,
  });
};

export {
  viewedPage,
  viewedContent,
  taskStarted,
  taskCompleted,
  courseRequested,
  courseApproved,
  CTAClicked,
};

import React, { useMemo } from 'react';
import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import htmr from 'htmr';
import { StaticImage } from 'gatsby-plugin-image';
import { v4 as uuidv4 } from 'uuid';
import * as styles from '../../../styles/admin/CourseDetails.module.scss';
import { TaskType } from '../../../models';
import { CourseAccessType } from '../../../API';

const { Panel } = Collapse;
/**
 *
 *
 * @return {*}
 */
const CourseDetails = ({
  course,
  browseRoute,
  courseDetailsCtaText,
  courseDetailsCtaHandler,
  disableStartCourseCta,
  domID,
}) => {
  const courseType = (index) => {
    if (index === 0) {
      return styles.beginner;
    } if (index === 1) {
      return styles.intermediate;
    } if (index === 2) {
      return styles.advanced;
    } return styles.expert;
  };

  const totalClasses = useMemo(() => {
    if (course?.modules?.length) {
      let count = 0;
      course?.modules.forEach((module) => {
        if (module?.tasks?.length) {
          count += module.tasks.filter((task) => task.taskType === TaskType.VIDEO)?.length;
        }
      });
      return count;
    }
    return 0;
  }, [course]);

  const isPrivateCourse = course?.accessType === CourseAccessType.PRIVATE;

  return (
    <div>
      {course && (
      <div className={`${styles.courseDetails} library-shaw-icon-new`}>
        <div className={styles.headingSection}>
          <div className={styles.courseSection}>
            <Link id="learner-course-details-back" to={browseRoute || '/admin/browse'}>
              <i className="icon-new-Left-Arrow-6" />
            </Link>
            <img
              width={200}
              height={120}
              src={course?.courseHeroBannerImage?.href}
              alt={course?.courseHeroBannerImage?.title}
            />
            <div className={styles.infoSection}>
              <div className={`${styles.part} ${isPrivateCourse ? styles.update : ''}`}>
                {!isPrivateCourse && (
                <p className={styles.info}>
                  Diploma in
                </p>
                )}
                <p className={styles.title}>
                  {course?.courseName}
                </p>
              </div>
            </div>
          </div>
          <button
            id={domID}
            disabled={disableStartCourseCta}
            className={courseDetailsCtaText === 'Assign' ? styles.hideCta : styles.cta}
            onClick={courseDetailsCtaHandler}
            type="button"
          >
            <i className="icon-new-Share1" />
            <p>{courseDetailsCtaText}</p>
          </button>
        </div>
        {!isPrivateCourse && <hr />}
        <div className={styles.courseInfoSection}>
          {!isPrivateCourse
          && (
          <div className={styles.accreditationCourseDetails}>
            <div className={styles.week}>
              <i className="icon-new-Play-31" />
              <div>
                <p>
                  {totalClasses}
                  {' '}
                  classes
                </p>
                <p>On Demand Learning</p>
              </div>
            </div>
            <div className={styles.skillLevel}>
              <i className="icon-new-Diamond" />
              <div>
                <p>Skill Level</p>
                <p>Beginner & Advanced</p>
              </div>
            </div>
            <div className={styles.certifiedBy}>
              <i className="icon-new-Play-31" />
              <div>
                <p>Certified by</p>
                <p>CPD</p>
              </div>
            </div>
            <div className={styles.language}>
              <i className="icon-new-Diamond" />
              <div>
                <p>Language</p>
                <p>English</p>
              </div>
            </div>
            <div className={styles.language}>
              <i className="icon-new-Diamond" />
              <div>
                <p>Sharable</p>
                <p>Diploma Certificate</p>
              </div>
            </div>
          </div>
          )}
          {!isPrivateCourse && <hr />}
          <div className={styles.bodySection}>
            <div className={styles.wrapper}>
              <div className={styles.courseInfo}>
                <h4 className={styles.sectionHeading}>
                  About the Course
                </h4>
                <div className={`${styles.courseDescription} ${isPrivateCourse ? styles.update : ''}`}>
                  {course.courseDescription && htmr(course.courseDescription)}
                </div>
              </div>
              {!isPrivateCourse
              && (
              <div className={styles.accreditationSection}>
                <p className={styles.accreditationSectionTitle}>Certified by</p>
                <div className={styles.imageWrapper}>
                  <div>
                    <StaticImage
                      src="../../../../static/images/shared/CPD.png"
                      alt="cpd"
                      height={80}
                    />
                  </div>
                </div>
              </div>
              )}
            </div>
            {!isPrivateCourse
            && (
            <div className={styles.courseJourneyWrapper}>
              <p className={styles.title}>Course Journey</p>
              <div className={styles.journey}>
                <div className={styles.moduleWrapper}>
                  <p className={styles.startJourney}>Starting today</p>
                  <div className={styles.divider} />
                  {course?.modules?.length > 0
                    ? (
                      course?.modules.map((_, moduleIndex) => (
                        <React.Fragment key={uuidv4()}>
                          <div className={styles.moduleDiv}>
                            <p className={styles.moduleName}>
                              Module
                              {' '}
                              0
                              {moduleIndex + 1}
                            </p>
                          </div>
                          <div className={styles.divider} />
                        </React.Fragment>
                      ))
                    )
                    : null}
                  <p className={styles.endJourney}>Finish and Get Diploma</p>
                </div>
              </div>
            </div>
            )}
            {!isPrivateCourse
            && (
            <div className={styles.moduleInfoSection}>
              <Collapse
                expandIconPosition="right"
                expandIcon={(expandProps) => {
                  if (expandProps?.isActive) {
                    return (
                      <span className={styles.collapse}>-</span>
                    );
                  }
                  return (
                    <span className={styles.expand}>+</span>
                  );
                }}
              >
                {course?.modules?.map((module, index) => (
                  <Panel
                    header={(
                      <div>
                        <p className={styles.moduleNo}>
                          <span>{`Module ${index + 1}`}</span>
                          <span
                            className={courseType(index)}
                          />
                        </p>
                        <p className={styles.moduleName}>{module.moduleName}</p>
                      </div>
                    )}
                    key={module?.id}
                  >
                    <Collapse
                      expandIconPosition="right"
                      className={styles.lessonAccordion}
                      expandIcon={(expandProps) => {
                        if (expandProps?.isActive) {
                          return (
                            <span className={`library-shaw-icon-new ${styles.lessonCollapse}`}>
                              <i className="icon-new-Right-arrow-1" />
                            </span>
                          );
                        }
                        return (
                          <span className={`library-shaw-icon-new ${styles.lessonExpand}`}>
                            <i className="icon-new-Right-arrow-1" />
                          </span>
                        );
                      }}
                    >
                      {module?.tasks.filter((task) => task.videoTask).map((lesson, i) => (
                        lesson?.videoTask?.lessonName && (
                        <Panel
                          header={`${i + 1}.${lesson?.videoTask?.lessonName}`}
                          key={lesson?.id}
                          className={styles.lesson}
                        >
                          {htmr(lesson?.videoTask?.lessonDescription)}
                        </Panel>
                        )
                      ))}
                    </Collapse>
                  </Panel>
                ))}
              </Collapse>
            </div>
            )}
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

CourseDetails.propTypes = {
  course: PropTypes.objectOf(PropTypes.any).isRequired,
  browseRoute: PropTypes.string.isRequired,
  courseDetailsCtaText: PropTypes.string.isRequired,
  courseDetailsCtaHandler: PropTypes.func.isRequired,
  disableStartCourseCta: PropTypes.bool.isRequired,
  domID: PropTypes.string,
};

CourseDetails.defaultProps = {
  domID: '',
};

export default CourseDetails;
